.carousel {
  --button-size: 40px;
  --dot-size: 20px;
  --dot-active-width: 30px;
  --bottom-bar-height: 60px;
  
  position: relative;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr var(--bottom-bar-height);
  overflow: hidden;
  background: var(--block-bg);
  border-radius: var(--radius-lg);
  user-select: none;
  box-sizing: border-box;

  // Адаптивные высоты
  min-height: 450px;
  @media screen and (min-width: 768px) { min-height: 550px; }
  @media screen and (min-width: 1024px) { min-height: 650px; }

  &__bottom-bar {
    display: flex;
    gap: var(--spacing-m);
    align-items: center;
    justify-content: space-between;
    padding: 0 var(--spacing-l);
    background: rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(2px);
  }
}

.carousel-track {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  touch-action: pan-y; // Для поддержки свайпов на тач-устройствах
}

.carousel-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: {
    property: opacity, visibility;
    duration: 0.4s;
    timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
  will-change: opacity;

  &.active {
    opacity: 1;
    visibility: visible;
    z-index: 1;
  }
}

.carousel-button {
  --icon-size: 25px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--button-size);
  height: var(--button-size);
  border: none;
  border-radius: var(--radius-md);
  background: var(--accent-color);
  color: var(--button-text);
  cursor: pointer;
  transition: background 0.2s ease;

  svg {
    width: var(--icon-size);
    height: var(--icon-size);
    transition: transform 0.2s ease;
  }

  &:hover {
    background: var(--accent-color-hover);
    svg { transform: scale(1.1); }
  }

  &:focus-visible {
    outline: 2px solid var(--accent-color);
    outline-offset: 2px;
  }
}

.carousel-dots {
  display: flex;
  flex: 1; 
  gap: 8px;
  align-items: center; 
  justify-content: center; 
  padding: 10px;
  height: 100%;
}

.dot {
  width: var(--dot-size);
  height: var(--dot-size);
  border-radius: var(--dot-size);
  background: var(--dot-inactive-color, #bbb);
  cursor: pointer;
  transition: {
    property: width, background;
    duration: 0.3s;
    timing-function: ease-out;
  }

  &.active {
    width: var(--dot-active-width);
    background: var(--accent-color);
  }

  &:hover:not(.active) {
    background: var(--dot-hover-color, #999);
  }
}

.button__group {
  display: flex;
  gap: var(--spacing-m);
  align-items: center;
}