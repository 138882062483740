[data-theme="dark"] {
  --red: #cb0e4e;
  --dark-red: #a00c3d;

// акцентный цвет
  --accent-color: #916107;/* Фон кнопки */
  --button-text: #e4dbdb;/* Текст на кнопке */
  --accent-color-hover: #917d0d;/* Фон кнопки при наведении */
  // моно цвета
  --btn-color-alt: #18181A;
  --btn-color-alt-hover: #1C1C1E;
  --btn-text-alt: #ffffff;



  --menu-item-text: #f8f0f0;
  --list-item-text: #15088b;
  --form-text:#6b6b6c; 
  --border-color1: #000;
  --border-color2: #555753;
  --border-color3: transparent;
  
  --background-main: #111113;
  --background-alt: #26232b;
  --panel-bg: #151516;
  --panel-item-bg: #26282B;
  --block-bg: #18181a;
  /* Тени */
  --shadow-color-1: rgba(255, 128, 0, 0.3); /* Новый цвет 1 */
  --shadow-color-2: rgba(255, 0, 0, 0.12); /* Новый цвет 2 */
  --font-color-light: #d4e5ee;
  --font-color-gray: #ebebf5bb;
  --link-color: rgb(146, 132, 207);
  --color-form-input: #2c2c30;
  --color-form-input-text: #fff;
  --qr-color: rgb(255, 255, 255);
}
