#js-show-iframe-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 293px;
    max-width: 100%;
    background: linear-gradient(138.4deg, #38bafe 26.49%, #2d73bc 79.45%);
    color: #fff;
    cursor: pointer;
   
}

#js-show-iframe-wrapper .pos-banner-fluid * {
    box-sizing: border-box
}

#js-show-iframe-wrapper .pos-banner-fluid .pos-banner-btn_2 {
    display: block;
    width: 240px;
    min-height: 56px;
    font-size: 18px;
    line-height: 24px;
    cursor: pointer;
    background: var(--accent-color);
    color: #fff;
    border: none;
    /* border-radius: var(--radius-xl); */
    outline: 0
}

#js-show-iframe-wrapper .pos-banner-fluid .pos-banner-btn_2:hover {
    background: #1d5deb
}

#js-show-iframe-wrapper .pos-banner-fluid .pos-banner-btn_2:focus {
    background: #2a63ad
}

#js-show-iframe-wrapper .pos-banner-fluid .pos-banner-btn_2:active {
    background: #2a63ad
}

@-webkit-keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0
    }

    1% {
        display: block;
        opacity: 0
    }

    100% {
        display: block;
        opacity: 1
    }
}

@keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0
    }

    1% {
        display: block;
        opacity: 0
    }

    100% {
        display: block;
        opacity: 1
    }
}

@font-face {
    font-family: LatoWebLight;
    src: url(https://pos.gosuslugi.ru/bin/fonts/Lato/fonts/Lato-Light.woff2) format("woff2"), url(https://pos.gosuslugi.ru/bin/fonts/Lato/fonts/Lato-Light.woff) format("woff"), url(https://pos.gosuslugi.ru/bin/fonts/Lato/fonts/Lato-Light.ttf) format("truetype");
    font-style: normal;
    font-weight: 400
}

@font-face {
    font-family: LatoWeb;
    src: url(https://pos.gosuslugi.ru/bin/fonts/Lato/fonts/Lato-Regular.woff2) format("woff2"), url(https://pos.gosuslugi.ru/bin/fonts/Lato/fonts/Lato-Regular.woff) format("woff"), url(https://pos.gosuslugi.ru/bin/fonts/Lato/fonts/Lato-Regular.ttf) format("truetype");
    font-style: normal;
    font-weight: 400
}

@font-face {
    font-family: LatoWebBold;
    src: url(https://pos.gosuslugi.ru/bin/fonts/Lato/fonts/Lato-Bold.woff2) format("woff2"), url(https://pos.gosuslugi.ru/bin/fonts/Lato/fonts/Lato-Bold.woff) format("woff"), url(https://pos.gosuslugi.ru/bin/fonts/Lato/fonts/Lato-Bold.ttf) format("truetype");
    font-style: normal;
    font-weight: 400
}

@font-face {
    font-family: RobotoWebLight;
    src: url(https://pos.gosuslugi.ru/bin/fonts/Roboto/Roboto-Light.woff2) format("woff2"), url(https://pos.gosuslugi.ru/bin/fonts/Roboto/Roboto-Light.woff) format("woff"), url(https://pos.gosuslugi.ru/bin/fonts/Roboto/Roboto-Light.ttf) format("truetype");
    font-style: normal;
    font-weight: 400
}

@font-face {
    font-family: RobotoWebRegular;
    src: url(https://pos.gosuslugi.ru/bin/fonts/Roboto/Roboto-Regular.woff2) format("woff2"), url(https://pos.gosuslugi.ru/bin/fonts/Roboto/Roboto-Regular.woff) format("woff"), url(https://pos.gosuslugi.ru/bin/fonts/Roboto/Roboto-Regular.ttf) format("truetype");
    font-style: normal;
    font-weight: 400
}

@font-face {
    font-family: RobotoWebBold;
    src: url(https://pos.gosuslugi.ru/bin/fonts/Roboto/Roboto-Bold.woff2) format("woff2"), url(https://pos.gosuslugi.ru/bin/fonts/Roboto/Roboto-Bold.woff) format("woff"), url(https://pos.gosuslugi.ru/bin/fonts/Roboto/Roboto-Bold.ttf) format("truetype");
    font-style: normal;
    font-weight: 400
}

@font-face {
    font-family: ScadaWebRegular;
    src: url(https://pos.gosuslugi.ru/bin/fonts/Scada/Scada-Regular.woff2) format("woff2"), url(https://pos.gosuslugi.ru/bin/fonts/Scada/Scada-Regular.woff) format("woff"), url(https://pos.gosuslugi.ru/bin/fonts/Scada/Scada-Regular.ttf) format("truetype");
    font-style: normal;
    font-weight: 400
}

@font-face {
    font-family: ScadaWebBold;
    src: url(https://pos.gosuslugi.ru/bin/fonts/Scada/Scada-Bold.woff2) format("woff2"), url(https://pos.gosuslugi.ru/bin/fonts/Scada/Scada-Bold.woff) format("woff"), url(https://pos.gosuslugi.ru/bin/fonts/Scada/Scada-Bold.ttf) format("truetype");
    font-style: normal;
    font-weight: 400
}

@font-face {
    font-family: Geometria;
    src: url(https://pos.gosuslugi.ru/bin/fonts/Geometria/Geometria.eot);
    src: url(https://pos.gosuslugi.ru/bin/fonts/Geometria/Geometria.eot?#iefix) format("embedded-opentype"), url(https://pos.gosuslugi.ru/bin/fonts/Geometria/Geometria.woff) format("woff"), url(https://pos.gosuslugi.ru/bin/fonts/Geometria/Geometria.ttf) format("truetype");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: Geometria-ExtraBold;
    src: url(https://pos.gosuslugi.ru/bin/fonts/Geometria/Geometria-ExtraBold.eot);
    src: url(https://pos.gosuslugi.ru/bin/fonts/Geometria/Geometria-ExtraBold.eot?#iefix) format("embedded-opentype"), url(https://pos.gosuslugi.ru/bin/fonts/Geometria/Geometria-ExtraBold.woff) format("woff"), url(https://pos.gosuslugi.ru/bin/fonts/Geometria/Geometria-ExtraBold.ttf) format("truetype");
    font-weight: 900;
    font-style: normal
}

#js-show-iframe-wrapper {
    background: var(--pos-banner-fluid-17__background)
}

#js-show-iframe-wrapper .pos-banner-fluid .pos-banner-btn_2 {
    width: 100%;
    min-height: 52px;
    background: var(--accent-color);
    color: #fff;
    font-size: 16px;
    font-family: LatoWeb, sans-serif;
    font-weight: 400;
    padding: 0;
    line-height: 1.2;
    border-radius: var(--radius-md);
    /* border: 2px solid var(--button-bg); */
}

#js-show-iframe-wrapper .pos-banner-fluid .pos-banner-btn_2:active,
#js-show-iframe-wrapper .pos-banner-fluid .pos-banner-btn_2:focus,
#js-show-iframe-wrapper .pos-banner-fluid .pos-banner-btn_2:hover {
    background: var(--accent-color-hover);
}

#js-show-iframe-wrapper .bf-17 {
    position: relative;
    display: grid;
    grid-template-columns: var(--pos-banner-fluid-17__grid-template-columns);
    grid-template-rows: var(--pos-banner-fluid-17__grid-template-rows);
    width: 100%;
    max-width: var(--pos-banner-fluid-17__max-width);
    box-sizing: border-box;
    grid-auto-flow: row dense
}

#js-show-iframe-wrapper .bf-17__decor {
    background: var(--pos-banner-fluid-17__bg-url) var(--pos-banner-fluid-17__bg-url-position) no-repeat;
    background-size: var(--pos-banner-fluid-17__bg-size);
    background-color: #f8efec;
    position: relative
}

#js-show-iframe-wrapper .bf-17__content {
    display: flex;
    flex-direction: column;
    padding: var(--pos-banner-fluid-17__content-padding);
    grid-row: var(--pos-banner-fluid-17__content-grid-row);
    justify-content: center
}

#js-show-iframe-wrapper .bf-17__text {
    margin: var(--pos-banner-fluid-17__text-margin);
    font-size: var(--pos-banner-fluid-17__text-font-size);
    line-height: 1.2;
    font-family: LatoWeb, sans-serif;
    font-weight: 700;
    color: #0b1f33
}

#js-show-iframe-wrapper .bf-17__bottom-wrap {
    display: flex;
    flex-direction: row;
    align-items: center
}

#js-show-iframe-wrapper .bf-17__logo-wrap {
    position: absolute;
    top: var(--pos-banner-fluid-17__logo-wrap-top);
    right: var(--pos-banner-fluid-17__logo-wrap-right)
}

#js-show-iframe-wrapper .bf-17__logo {
    width: var(--pos-banner-fluid-17__logo-width);
    margin-left: 1px
}

#js-show-iframe-wrapper .bf-17__slogan {
    font-family: LatoWeb, sans-serif;
    font-weight: 700;
    font-size: var(--pos-banner-fluid-17__slogan-font-size);
    line-height: 1.2;
    color: #005ca9
}

#js-show-iframe-wrapper .bf-17__btn-wrap {
    width: 100%;
    max-width: var(--pos-banner-fluid-17__button-wrap-max-width)
}
.gosuslugi{
    /* padding: var(--spacing-m); */
    overflow: hidden;
    border-radius: var(--radius-lg);
    
}