.nav {
  background-color: var(--background-main);
  grid-area: nav;
  width: 100%;
  height: calc(100vh - var(--header-height));
  position: sticky;
  top:  var(--header-height)  ;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; 
  

  &__container {
    height: 100%;
    width: 100%;
    // padding: var(--spacing-l) var(--spacing-m);
  
  }

  /* Для мобильных устройств */

  @media screen and (max-width: 768px) {
    position: fixed;

    top: 0;
    left: 0;
    z-index: 800;
    transform: translateY(100%); 
    opacity: 0; 
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    padding: var(--spacing-m);
    padding-bottom: 70px;
    &--active {
      transform: translateY(0); 
      opacity: 1; 
    }
  }

  &__container {
    // font-size: var(--spacing-xxs);
    color: var(--font-color-light);
    overflow-y: auto;
    a {
      color: var(--font-color-light);
    }
  
  }

  ul,
  ol,
  li {
    padding: 0;
    margin: 0;
    list-style: none;
  }
 &__list-item {
    display: flex;
    flex-direction: column;
    row-gap: var(--spacing-xs);
  }
  .ui-vertical-navigation-item__wrapper {
    padding: var(--spacing-xs) var(--spacing-m);
    border-radius: var(--radius-md);
    background-color: var(--panel-item-bg);
    color: var(--menu-item-text);
    &:hover {
      background-color: var(--accent-color-hover);
      color: var(--button-text);
    }
  }



  .Tree {
    display: flex;
    align-items: center;
  }
  // .hover__item {
  //   // background-color: red;
  // }
}

.menu__bottom {
  width: 80%;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.nav_menu_tree-header {
  position: relative;
  &-icon {
    position: absolute;
    top: 50%;
    right: var(--spacing-xxs);
    width: 40px;
    height: 40px; // background-color: red;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.nav_menu-content_tree {
  background-color: var(--panel-item-bg);

  border-radius: var(--radius-md);
  display: flex;
  flex-direction: column;
  // row-gap: var(--spacing-xs);
  &-list {
    padding: 0 var(--spacing-xxs);
    display: flex;
    flex-direction: column;
    row-gap: var(--spacing-xs);

    .nav_menu-content_item {
      &:first-child {
        margin-top: var(--spacing-xs);
      }
      &:last-child {
        margin-bottom: var(--spacing-xs);
      }
    }
  }
}
.nav_menu-content-separator {
  // background-color: var(--panel-item-bg);

  // border-radius: var(--radius-md);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  color: var(--menu-item-text);
  padding: var(--spacing-xs) var(--spacing-m);
  div {
    text-align: left;
  }
}

.nav_menu_tree-header-icon {
  transition: transform 0.3s ease;
}

.nav_menu_tree-header-icon.open {
  transform: translateY(-50%) rotate(180deg); /* Поворот иконки вниз */
}

.nav_menu_tree-header-icon.closed {
  transform: translateY(-50%) rotate(0deg); /* Поворот иконки вверх */
}

.nav-menu-close {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--menu-item-text);

  background-color: var(--panel-item-bg);
  border-radius: var(--radius-md);
  svg {
    width: 30px;
    height: 30px;
  }
}
