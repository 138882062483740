.image-viewer-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(55px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .image-viewer-content {
    max-width: 90%;
    max-height: 90%;
  width: 90%;
  height: 90%;

    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    backface-visibility: hidden;
    transform: translateZ(0);
   
  }
  
  .image-viewer-image {
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.15s ease-out;
 
  }
  .image-viewer{
  &__controls{
    z-index: 99;
    display: flex;
    position: absolute;
    gap: var(--spacing-m);
    top: 20px;

  }}