.NavMobile {
  width: 100vw;
  height: 70px;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0 var(--spacing-m);
  z-index: 900;

  background-color: var(--block-bg);
 
  display: grid;
  grid-template-columns:  1fr 1fr 1fr;
  grid-template-areas: " . menu  .";
                                                                    
  .menu {
    grid-area: menu;
  }
  &__item {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: end;
    color: var(--font-color-gray);
    gap: var(--spacing-xs);
    // padding: var(--spacing-xs);
  }
  &__title {
    text-align: center;
    margin-bottom: var(--spacing-s);
  
    font-size: var(--font-size-small);
  }
  &__btn-menu {
    position: absolute;
    left: 50%;
    top: -40%;
    transform: translateX(-50%);
    height: 60px;
    width: 60px;
    background-color: var(--accent-color);
   

    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 16px;
      height: 16px;
    }
  }
  &__logo{
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
      // width: 16px;
      height: 18px;
    }
  }
}
